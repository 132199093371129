// ./context/AuthContext.tsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../services/auth.service';

interface AuthContextType {
  isAuthenticated: boolean;
  isValidated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  token: string | null;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [auth, setAuth] = useState<boolean>(AuthService.IsAuthenticated());
  const [validated, setValidated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(AuthService.GetAuthToken());
  const navigate = useNavigate();

  const handleLogin = async (username: string, password: string) => {
    const token = await AuthService.Login(username, password);
    await AuthService.ValidateEmail();
    const role = AuthService.GetRole();
    if (token && role) {
      setAuth(true);
      setValidated(true);
      setToken(token);
      navigate('/products');
    } else {
      setAuth(false);
      setToken(null);
    }
  };

  const handleLogout = () => {
    AuthService.LogOut();
    setAuth(false);
    setToken(null);
    navigate('/');
  };

  useEffect(() => {
    setAuth(AuthService.IsAuthenticated());
    setToken(AuthService.GetAuthToken());
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isValidated: validated,
        isAuthenticated: auth,
        login: handleLogin,
        logout: handleLogout,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe usarse dentro de un AuthContextProvider');
  }
  return context;
};
